import { ISolarLimits, ISolarSegments } from 'types/building/Pv';
import { Nullable } from 'types/util';
import { proxy } from 'valtio';

export const DEFAULT_SOLAR_LIMITS: ISolarLimits = {
  yearlyReduction: [0, 100],
  yearlyIrradiation: [0, 1320],
  reductionMonthlyMin: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  reductionMonthlyMax: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
  irradiationMonthlyMin: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  irradiationMonthlyMax: [110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110],
};

interface IDataStore {
  solarSegments: Nullable<ISolarSegments>;
  solarLimits: ISolarLimits;
}

const dataStore = proxy<IDataStore>({
  solarSegments: null,
  solarLimits: DEFAULT_SOLAR_LIMITS,
});

export default dataStore;
