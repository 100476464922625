import { XYZ } from 'types/location/coordinates';

export enum ETerrainWidth {
  SMALL = 200,
  LARGE = 500,
}

export enum ETerrainImageType {
  SATELLITE = 'satellite',
  ROADMAP = 'roadmap',
  CADASTRE = 'cadastre',
}

export type TTerrain = XYZ[][];

export interface ITerrainProperties {
  id: string;
  highestPoint: XYZ;
  lowestPoint: XYZ;
  midpointElevation: number;
  terrainWidth: number;
}

export interface ITerrainWithProperties {
  terrain: TTerrain;
  properties: ITerrainProperties;
}
