import { XYZ } from "types/location/coordinates";
import { IFaceSegment } from './FaceSegment';

export interface IBaseFace {
  id: string;
  floorId: string;
  coordinates: XYZ[];
  segments: IFaceSegment[];
}

export interface IOtherFace extends IBaseFace {
  type: EFaceType.FLOOR | EFaceType.ROOF | EFaceType.CEILING;
}

export interface IWallFace extends IBaseFace {
  order: number;
  type: EFaceType.WALL;
}

export type TFace = IWallFace | IOtherFace;

export enum EFaceType {
  FLOOR = 'floor',
  CEILING = 'ceiling',
  WALL = 'wall',
  ROOF = 'roof',
  TERRACE = 'terrace',
}
