import BaseLayoutError from 'components/layouts/BaseLayout/BaseLayoutError';
import PrivateRouteWrapper from 'components/utility/PrivateRouteWrapper';
import NotFound from 'pages/NotFound';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import NavigateLast from 'routes/components/NavigateLast';
import NavigateWithParams from 'routes/components/NavigateWithParams';
import { APP_PATHS, FULL_APP_PATHS } from 'routes/routers/app/appPaths';
import AppWrapper from './AppWrapper';

const appRouter = createBrowserRouter([
  {
    element: <AppWrapper />,
    ErrorBoundary: () => {
      return (
        <BaseLayoutError
          resetError={() => {
            window.location.reload();
          }}
        />
      );
    },
    children: [
      {
        path: APP_PATHS.AUTHENTICATION.ROOT,
        lazy: async () => {
          const { default: Authentication } = await import('pages/Authentication');

          return { Component: Authentication };
        },
        children: [
          {
            index: true,
            element: <Navigate replace to={APP_PATHS.AUTHENTICATION.LOGIN} />,
          },
          {
            path: APP_PATHS.AUTHENTICATION.LOGIN,
            lazy: async () => {
              const { default: LogIn } = await import('pages/Authentication/LogIn');

              return { Component: LogIn };
            },
          },
          {
            path: APP_PATHS.AUTHENTICATION.SIGNUP,
            lazy: async () => {
              const { default: SignUp } = await import('pages/Authentication/SignUp');

              return { Component: SignUp };
            },
          },
          {
            path: APP_PATHS.AUTHENTICATION.FORGOT_PASSWORD,
            lazy: async () => {
              const { default: ForgotPassword } = await import('pages/Authentication/ForgotPassword');

              return { Component: ForgotPassword };
            },
          },
        ],
      },
      {
        element: <PrivateRouteWrapper />,
        children: [
          {
            path: APP_PATHS.EXPLORE.ROOT,
            lazy: async () => {
              const { default: Explore } = await import('pages/App/Explore');

              return { Component: Explore };
            },
            children: [
              {
                index: true,
                lazy: async () => {
                  const { Location: ExploreLocation } = await import('pages/App/Explore');

                  return { Component: ExploreLocation };
                },
              },
            ],
          },
          {
            path: APP_PATHS.DASHBOARD.ROOT,
            lazy: async () => {
              const { default: Dashboard } = await import('pages/Dashboard');

              return { Component: Dashboard };
            },
            children: [
              {
                index: true,
                element: <Navigate replace to={APP_PATHS.DASHBOARD.PROJECTS.ROOT} />,
              },
              {
                path: APP_PATHS.DASHBOARD.PROJECTS.ROOT,
                lazy: async () => {
                  const { default: Projects } = await import('pages/Dashboard/Projects');

                  return { Component: Projects };
                },
              },
              {
                path: APP_PATHS.DASHBOARD.COMPARE.ROOT,
                lazy: async () => {
                  const { Compare } = await import('pages/Dashboard/Projects');

                  return { Component: Compare };
                },
              },
              {
                path: APP_PATHS.DASHBOARD.PROFILE.ROOT,
                lazy: async () => {
                  const { Profile } = await import('pages/Dashboard');

                  return { Component: Profile };
                },
                children: [
                  {
                    index: true,
                    element: <Navigate replace to={APP_PATHS.DASHBOARD.PROFILE.PERSONAL_DETAILS} />,
                  },
                  {
                    path: APP_PATHS.DASHBOARD.PROFILE.PERSONAL_DETAILS,
                    lazy: async () => {
                      const { PersonalDetails } = await import('pages/Dashboard/Profile');

                      return { Component: PersonalDetails };
                    },
                  },
                  {
                    path: APP_PATHS.DASHBOARD.PROFILE.SECURITY,
                    lazy: async () => {
                      const { Security } = await import('pages/Dashboard/Profile');

                      return { Component: Security };
                    },
                  },
                  {
                    path: APP_PATHS.DASHBOARD.PROFILE.LANGUAGE,
                    lazy: async () => {
                      const { Language } = await import('pages/Dashboard/Profile');

                      return { Component: Language };
                    },
                  },
                  {
                    path: APP_PATHS.DASHBOARD.PROFILE.INTEGRATIONS,
                    lazy: async () => {
                      const { Integrations } = await import('pages/Dashboard/Profile');

                      return { Component: Integrations };
                    },
                  },
                ],
              },
              {
                path: APP_PATHS.DASHBOARD.BILLING.ROOT,
                lazy: async () => {
                  const { Billing } = await import('pages/Dashboard');

                  return { Component: Billing };
                },
                children: [
                  {
                    index: true,
                    element: <Navigate replace to={APP_PATHS.DASHBOARD.BILLING.OVERVIEW} />,
                  },
                  {
                    path: APP_PATHS.DASHBOARD.BILLING.OVERVIEW,
                    lazy: async () => {
                      const { Overview } = await import('pages/Dashboard/Billing');

                      return { Component: Overview };
                    },
                  },
                  {
                    path: APP_PATHS.DASHBOARD.BILLING.PURCHASE,
                    lazy: async () => {
                      const { Purchase } = await import('pages/Dashboard/Billing');

                      return { Component: Purchase };
                    },
                  },
                ],
              },
              {
                path: APP_PATHS.DASHBOARD.LIBRARY.ROOT,
                lazy: async () => {
                  const { Library } = await import('pages/Dashboard');

                  return {
                    element: isMobile ? <Navigate replace to={APP_PATHS.DASHBOARD.PROJECTS.ROOT} /> : <Library />,
                  };
                },
              },
            ],
          },
          {
            path: APP_PATHS.PROJECT.ROOT,
            lazy: async () => {
              const { default: Project } = await import('pages/App/Project');

              return { Component: Project };
            },
            children: [
              {
                index: true,
                element: <Navigate replace to={APP_PATHS.PROJECT.LOCATION.ROOT} />,
              },
              {
                path: APP_PATHS.PROJECT.LOCATION.ROOT,
                lazy: async () => {
                  const { Location: ProjectLocation } = await import('pages/App/Project');

                  return { Component: ProjectLocation };
                },
              },
              {
                path: APP_PATHS.PROJECT.LAW.ROOT,
                lazy: async () => {
                  const { Law: ProjectLaw } = await import('pages/App/Project');

                  return {
                    element: isMobile ? (
                      <Navigate replace to={APP_PATHS.PROJECT.LOCATION.ROOT} relative='path' />
                    ) : (
                      <ProjectLaw />
                    ),
                  };
                },
              },
              {
                path: APP_PATHS.PROJECT.DESIGN.ROOT,
                lazy: async () => {
                  const { Design: ProjectDesign } = await import('pages/App/Project');

                  return {
                    element: isMobile ? (
                      <NavigateWithParams replace to={APP_PATHS.PROJECT.LOCATION.ROOT} relative='path' />
                    ) : (
                      <ProjectDesign />
                    ),
                  };
                },
                children: [
                  {
                    index: true,
                    element: (
                      <NavigateLast path={['project', 'design']} initialView={APP_PATHS.PROJECT.DESIGN.FORM.ROOT} />
                    ),
                  },
                  {
                    path: APP_PATHS.PROJECT.DESIGN.FORM.ROOT,
                    lazy: async () => {
                      const { default: DesignFormView } = await import(
                        'pages/App/Project/views/Design/views/DesignFormView'
                      );

                      return { Component: DesignFormView };
                    },
                    children: [
                      {
                        index: true,
                        element: (
                          <NavigateLast
                            path={['project', 'design', 'form']}
                            initialView={APP_PATHS.PROJECT.DESIGN.FORM.EXISTING_BUILDINGS}
                          />
                        ),
                      },
                      {
                        path: APP_PATHS.PROJECT.DESIGN.FORM.EXISTING_BUILDINGS,
                        lazy: async () => {
                          const { default: DesignFormExistingBuildingsView } = await import(
                            'pages/App/Project/views/Design/views/DesignFormView/views/DesignFormExistingBuildingsView'
                          );

                          return { Component: DesignFormExistingBuildingsView };
                        },
                      },
                      {
                        path: APP_PATHS.PROJECT.DESIGN.FORM.BUILDINGS,
                        lazy: async () => {
                          const { default: DesignFormBuildingsView } = await import(
                            'pages/App/Project/views/Design/views/DesignFormView/views/DesignFormBuildingsView'
                          );

                          return { Component: DesignFormBuildingsView };
                        },
                      },
                    ],
                  },
                  {
                    path: APP_PATHS.PROJECT.DESIGN.FACADE.ROOT,
                    lazy: async () => {
                      const { default: DesignFacadeView } = await import(
                        'pages/App/Project/views/Design/views/DesignFacadeView'
                      );

                      return { Component: DesignFacadeView };
                    },
                    children: [
                      {
                        index: true,
                        element: <NavigateWithParams replace to={APP_PATHS.PROJECT.DESIGN.FACADE.BUILDINGS} />,
                      },
                      {
                        path: APP_PATHS.PROJECT.DESIGN.FACADE.BUILDINGS,
                        lazy: async () => {
                          const { default: DesignFacadeBuildingsView } = await import(
                            'pages/App/Project/views/Design/views/DesignFacadeView/views/DesignFacadeBuildingsView'
                          );

                          return { Component: DesignFacadeBuildingsView };
                        },
                      },
                    ],
                  },
                  {
                    path: APP_PATHS.PROJECT.DESIGN.PHOTOVOLTAICS.ROOT,
                    lazy: async () => {
                      const { default: DesignPhotovoltaicsView } = await import(
                        'pages/App/Project/views/Design/views/DesignPhotovoltaicsView/DesignPhotovoltaicsView'
                      );

                      return { Component: DesignPhotovoltaicsView };
                    },
                    children: [
                      {
                        index: true,
                        element: <NavigateWithParams replace to={APP_PATHS.PROJECT.DESIGN.PHOTOVOLTAICS.BUILDINGS} />,
                      },
                      {
                        path: APP_PATHS.PROJECT.DESIGN.PHOTOVOLTAICS.BUILDINGS,
                        lazy: async () => {
                          const { default: DesignPhotovoltaicsBuildingsView } = await import(
                            'pages/App/Project/views/Design/views/DesignPhotovoltaicsView/views/DesignPhotovoltaicsBuildingsView'
                          );

                          return { Component: DesignPhotovoltaicsBuildingsView };
                        },
                      },
                    ],
                  },
                  {
                    path: APP_PATHS.PROJECT.DESIGN.USAGE.ROOT,
                    lazy: async () => {
                      const { default: DesignUsageView } = await import(
                        'pages/App/Project/views/Design/views/DesignUsageView'
                      );

                      return { Component: DesignUsageView };
                    },
                    children: [
                      {
                        index: true,
                        element: <NavigateWithParams replace to={APP_PATHS.PROJECT.DESIGN.USAGE.BUILDINGS} />,
                      },
                      {
                        path: APP_PATHS.PROJECT.DESIGN.USAGE.BUILDINGS,
                        lazy: async () => {
                          const { default: DesignUsageBuildingsView } = await import(
                            'pages/App/Project/views/Design/views/DesignUsageView/views/DesignUsageBuildingsView'
                          );

                          return { Component: DesignUsageBuildingsView };
                        },
                      },
                    ],
                  },
                  {
                    path: APP_PATHS.PROJECT.DESIGN.PARKING_LOTS.ROOT,
                    lazy: async () => {
                      const { default: DesignParkingLotsView } = await import(
                        'pages/App/Project/views/Design/views/DesignParkingLotsView'
                      );

                      return { Component: DesignParkingLotsView };
                    },
                    children: [
                      {
                        index: true,
                        element: <NavigateWithParams replace to={APP_PATHS.PROJECT.DESIGN.PARKING_LOTS.BUILDINGS} />,
                      },
                      {
                        path: APP_PATHS.PROJECT.DESIGN.PARKING_LOTS.BUILDINGS,
                        lazy: async () => {
                          const { default: DesignParkingLotsBuildingsView } = await import(
                            'pages/App/Project/views/Design/views/DesignParkingLotsView/views/DesignParkingLotsBuildingsView'
                          );

                          return { Component: DesignParkingLotsBuildingsView };
                        },
                      },
                    ],
                  },
                  {
                    path: APP_PATHS.PROJECT.DESIGN.LANDSCAPE.ROOT,
                    lazy: async () => {
                      const { default: DesignLandscapeView } = await import(
                        'pages/App/Project/views/Design/views/DesignLandscapeView'
                      );

                      return { Component: DesignLandscapeView };
                    },
                    children: [
                      {
                        index: true,
                        element: <NavigateWithParams replace to={APP_PATHS.PROJECT.DESIGN.LANDSCAPE.VEGETATION} />,
                      },
                      {
                        path: APP_PATHS.PROJECT.DESIGN.LANDSCAPE.VEGETATION,
                        lazy: async () => {
                          const { default: DesignLandscapeVegetationView } = await import(
                            'pages/App/Project/views/Design/views/DesignLandscapeView/views/DesignLandscapeVegetationView'
                          );

                          return { Component: DesignLandscapeVegetationView };
                        },
                      },
                    ],
                  },
                  {
                    path: APP_PATHS.PROJECT.DESIGN.TERRAIN.ROOT,
                    lazy: async () => {
                      const { default: DesignTerrainView } = await import(
                        'pages/App/Project/views/Design/views/DesignTerrainView'
                      );

                      return { Component: DesignTerrainView };
                    },
                    children: [
                      {
                        index: true,
                        element: <NavigateWithParams replace to={APP_PATHS.PROJECT.DESIGN.TERRAIN.MODIFY} />,
                      },
                      {
                        path: APP_PATHS.PROJECT.DESIGN.TERRAIN.MODIFY,
                        lazy: async () => {
                          const { default: DesignTerrainModifyView } = await import(
                            'pages/App/Project/views/Design/views/DesignTerrainView/views/DesignTerrainBuildingsView'
                          );

                          return { Component: DesignTerrainModifyView };
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: APP_PATHS.PROJECT.SUMMARY.ROOT,
                lazy: async () => {
                  const { Summary: ProjectSummary } = await import('pages/App/Project');

                  return { Component: ProjectSummary };
                },
              },
            ],
          },
          {
            path: APP_PATHS.PROJECT_NOT_FOUND.ROOT,
            lazy: async () => {
              const { default: ProjectNotFound } = await import('pages/ProjectNotFound');

              return { Component: ProjectNotFound };
            },
          },
          {
            path: APP_PATHS.PAYMENT.ROOT,
            lazy: async () => {
              const { default: Payment } = await import('pages/Payment');

              return { Component: Payment };
            },
            children: [
              {
                index: true,
                element: <Navigate to={FULL_APP_PATHS.DASHBOARD.ROOT} replace />,
              },
              {
                path: APP_PATHS.PAYMENT.SUCCESS,
                lazy: async () => {
                  const { PaymentSuccess } = await import('pages/Payment');

                  return { Component: PaymentSuccess };
                },
              },
            ],
          },
        ],
      },
      {
        path: APP_PATHS.VIEWER.ROOT,
        element: <Outlet />,
        children: [
          {
            path: APP_PATHS.VIEWER.EXPLORE,
            lazy: async () => {
              const { default: ExploreViewer } = await import('pages/App/Explore/ExploreViewer');

              return { Component: ExploreViewer };
            },
          },
          {
            path: APP_PATHS.VIEWER.PROJECT,
            lazy: async () => {
              const { default: ProjectViewer } = await import('pages/App/Project/ProjectViewer');

              return { Component: ProjectViewer };
            },
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);

export default appRouter;
