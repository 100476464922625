import { StandardTextFieldProps, TextField, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import React, { ForwardedRef } from 'react';
import styles from './styles';

interface LargeFieldProps extends StandardTextFieldProps {
  sx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
}

const LargeField = React.forwardRef((props: LargeFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { label, sx, InputLabelProps, ...otherProps } = props;

  return (
    <TextField
      ref={ref}
      label={label}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      sx={[styles.textField, !!label && styles.textFieldWithLabel, sx ?? {}]}
      {...otherProps}
    />
  );
});

export default LargeField;
