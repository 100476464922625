import { Point } from 'geojson';
import { XYZ } from "types/location/coordinates";
import { proxyWithHistory } from 'valtio/utils';

export enum EVegetationSource {
  SWISSTOPO = 'swisstopo',
  USER = 'user',
}

export enum EVegetationType {
  SPRUCE = 'spruce',
  MAPLE = 'maple',
  BIRCH = 'birch',
  CYPRESS = 'cypress',
  BUSH = 'bush',
}

export const VEGETATION_TYPE_SIZE: Record<EVegetationType, [number, number]> = {
  [EVegetationType.SPRUCE]: [6, 12],
  [EVegetationType.MAPLE]: [7, 10],
  [EVegetationType.BIRCH]: [4.5, 10],
  [EVegetationType.CYPRESS]: [1.5, 4],
  [EVegetationType.BUSH]: [2, 1.8],
};

export interface ISwissTopoTree {
  id: number;
  uuid: string;
  change_date: string;
  first_date: string;
  count_year: number;
  count_month: number;
  revision_year: number;
  revision_month: number;
  ground_change: string;
  origin: number;
  origin_year: number;
  origin_month: number;
  object_type: string;
  revision_doc: number;
  geom: Point;
}

export interface IVegetationProperties {
  height: number;
  diameter: number;
  type: EVegetationType;
  visible: boolean;
  position: XYZ;
  source: EVegetationSource;
}

export interface IVegetation {
  id: string;
  isModified: boolean;
  properties: IVegetationProperties;
}

interface ILandscapeStoreState {
  vegetations: IVegetation[];
}

const landscapeStore = proxyWithHistory<ILandscapeStoreState>(
  {
    vegetations: [],
  },
  false,
);

export const resetLandscapeStoreHistory = () => {
  const lastItem = landscapeStore.history.snapshots[landscapeStore.history.index];

  landscapeStore.history.wip = undefined;
  landscapeStore.history.index = 0;
  landscapeStore.history.snapshots = [lastItem];
};

export default landscapeStore;
