import { generateFullPaths } from 'utils/router';

interface IAppPaths {
  AUTHENTICATION: {
    ROOT: string;
    LOGIN: string;
    SIGNUP: string;
    FORGOT_PASSWORD: string;
  };
  DASHBOARD: {
    ROOT: string;
    PROJECTS: {
      ROOT: string;
    };
    COMPARE: {
      ROOT: string;
    };
    LIBRARY: {
      ROOT: string;
    };
    PROFILE: {
      ROOT: string;
      PERSONAL_DETAILS: string;
      SECURITY: string;
      LANGUAGE: string;
      INTEGRATIONS: string;
    };
    BILLING: {
      ROOT: string;
      OVERVIEW: string;
      PURCHASE: string;
    };
  };
  EXPLORE: {
    ROOT: string;
  };
  PROJECT: {
    ROOT: string;
    LOCATION: {
      ROOT: string;
    };
    LAW: {
      ROOT: string;
    };
    DESIGN: {
      ROOT: string;
      FORM: {
        ROOT: string;
        EXISTING_BUILDINGS: string;
        BUILDINGS: string;
      };
      FACADE: {
        ROOT: string;
        BUILDINGS: string;
      };
      PHOTOVOLTAICS: {
        ROOT: string;
        BUILDINGS: string;
      };
      LANDSCAPE: {
        ROOT: string;
        VEGETATION: string;
      };
      TERRAIN: {
        ROOT: string;
        MODIFY: string;
      };
      USAGE: {
        ROOT: string;
        BUILDINGS: string;
      };
      PARKING_LOTS: {
        ROOT: string;
        BUILDINGS: string;
        AREAS: string;
      };
    };
    SUMMARY: {
      ROOT: string;
    };
  };
  PROJECT_NOT_FOUND: {
    ROOT: string;
  };
  PAYMENT: {
    ROOT: string;
    SUCCESS: string;
  };
  VIEWER: {
    ROOT: string;
    EXPLORE: string;
    PROJECT: string;
  };
}

const APP_PATHS: IAppPaths = {
  AUTHENTICATION: {
    ROOT: 'auth',
    LOGIN: 'login',
    SIGNUP: 'signup',
    FORGOT_PASSWORD: 'forgot-password',
  },
  DASHBOARD: {
    ROOT: '',
    PROJECTS: {
      ROOT: 'projects',
    },
    COMPARE: {
      ROOT: 'compare',
    },
    LIBRARY: {
      ROOT: 'library',
    },
    PROFILE: {
      ROOT: 'profile',
      PERSONAL_DETAILS: 'personal-details',
      SECURITY: 'security',
      LANGUAGE: 'language',
      INTEGRATIONS: 'integrations',
    },
    BILLING: {
      ROOT: 'billing',
      OVERVIEW: 'overview',
      PURCHASE: 'purchase',
    },
  },
  EXPLORE: {
    ROOT: 'explore',
  },
  PROJECT: {
    ROOT: 'project/:projectId',
    LOCATION: {
      ROOT: 'location',
    },
    LAW: {
      ROOT: 'law',
    },
    DESIGN: {
      ROOT: 'design',
      FORM: {
        ROOT: 'form',
        EXISTING_BUILDINGS: 'existing-buildings',
        BUILDINGS: 'buildings',
      },
      FACADE: {
        ROOT: 'facade',
        BUILDINGS: 'buildings',
      },
      PHOTOVOLTAICS: {
        ROOT: 'photovoltaics',
        BUILDINGS: 'buildings',
      },
      USAGE: {
        ROOT: 'usage',
        BUILDINGS: 'buildings',
      },
      PARKING_LOTS: {
        ROOT: 'parking-lots',
        BUILDINGS: 'buildings',
        AREAS: 'areas',
      },
      LANDSCAPE: {
        ROOT: 'landscape',
        VEGETATION: 'vegetation',
      },
      TERRAIN: {
        ROOT: 'terrain',
        MODIFY: 'modify',
      },
    },
    SUMMARY: {
      ROOT: 'summary',
    },
  },
  PAYMENT: {
    ROOT: 'payment',
    SUCCESS: 'success',
  },
  VIEWER: {
    ROOT: 'viewer',
    EXPLORE: 'explore',
    PROJECT: 'project/:projectId',
  },
  PROJECT_NOT_FOUND: {
    ROOT: 'project-not-found',
  },
};

const FULL_APP_PATHS = generateFullPaths(APP_PATHS);

export { APP_PATHS, FULL_APP_PATHS };
