import chroma, { Color } from 'chroma-js';
import { EMapLineType } from 'components/features/Map/types';
import { Feature } from 'geojson';
import theme from 'theme';

export type RGBAColor = [number, number, number, number];

export const CADASTRE_SOURCE_ID = 'switzerland.cadastre';

export const BUILDING_NON_GEODIENSTE_SOURCE_ID = 'swisstopo.building_footprints_non_geodienste';
export const LAND_COVER_SOURCE_ID = 'geodienste.cadastre_land_cover';
export const LAND_COVER_TYPE_BUILDING = 'Gebaeude Einzelhaus';

export const CONSTRUCTION_LINES_SOURCE_ID = 'gis.combined_construction_lines';

export const HELPLINES_LINE_WIDTH = 2;
export const DEFAULT_FILL_OPACITY = 0.5;
export const DEFAULT_LAYER_FILL_COLOR = [39, 77, 168, 255 * 0.5];
export const DEFAULT_LAYER_LINE_COLOR = [39, 77, 168, 255];

export const EDITABLE_GEOJSON_LAYER_OPTIONS = {
  getFillColor: (feature: Feature) => {
    if (!feature.properties) return DEFAULT_LAYER_FILL_COLOR;

    const type = feature.properties.type;

    const color = MAP_LINE_STYLES[type as EMapLineType];
    if (!color) return DEFAULT_LAYER_FILL_COLOR;

    const { fillColor, alpha } = color;

    const [r, g, b, a] = fillColor.rgba();

    return [r, g, b, a * (255 * (alpha ?? DEFAULT_FILL_OPACITY))];
  },
  getLineColor: (feature: Feature) => {
    if (!feature.properties) return DEFAULT_LAYER_LINE_COLOR;

    const type = feature.properties.type;

    const color = MAP_LINE_STYLES[type as EMapLineType];
    if (!color) return DEFAULT_LAYER_LINE_COLOR;

    const [r, g, b, a] = color.strokeColor.rgba();

    return [r, g, b, a * 255];
  },
  getDashArray: (feature: Feature) => {
    if (!feature.properties) return [0, 0];

    const type = feature.properties.type;

    const color = MAP_LINE_STYLES[type as EMapLineType];
    if (!color) return [0, 0];

    const { dashArray } = color;

    return dashArray ?? [];
  },
  pickingRadius: 10,
  pickingLineWidthExtraPixels: 5,
  getEditHandlePointColor: () => [31, 41, 55, 255] as RGBAColor,
  getEditHandlePointOutlineColor: () => [239, 246, 255, 255] as RGBAColor,
  getTentativeLineColor: () => [156, 163, 175, 255] as RGBAColor,
  getEditHandleIconSize: 10,
  getLineWidth: HELPLINES_LINE_WIDTH,
};

interface IMapLineStyle {
  fillColor: Color;
  strokeColor: Color;
  alpha?: number;
  dashArray?: number[];
}

export const MAP_LINE_STYLES: Record<EMapLineType, IMapLineStyle> = {
  [EMapLineType.ZONE_BORDER]: {
    fillColor: chroma('#5c6061'),
    strokeColor: chroma('#5c6061'),
  },
  [EMapLineType.PLOT_BORDER]: {
    fillColor: chroma('#1FC676'),
    strokeColor: chroma('#1fc676'),
    alpha: 0.4,
  },
  [EMapLineType.CONSTRUCTION_LINE]: {
    fillColor: chroma('#858da2'),
    strokeColor: chroma('#858da2'),
  },
  [EMapLineType.FOREST_LINE]: {
    fillColor: chroma('#22ad80'),
    strokeColor: chroma('#22ad80'),
  },
  [EMapLineType.WATER_LINE]: {
    fillColor: chroma('#0A369d'),
    strokeColor: chroma('#0A369d'),
  },
  [EMapLineType.DISTANCE_LINE]: {
    fillColor: chroma('#5d02a0'),
    strokeColor: chroma('#5d02a0'),
  },
  [EMapLineType.HELP_LINE]: {
    fillColor: chroma('#c19d15'),
    strokeColor: chroma('#c19d15'),
  },
  [EMapLineType.CONSTRUCTION_WINDOW]: {
    fillColor: chroma('#996404'),
    strokeColor: chroma('#996404'),
  },
  [EMapLineType.ACCOUNTABLE_PLOT]: {
    fillColor: chroma('#996404'),
    strokeColor: chroma('#996404'),
  },
  [EMapLineType.NOT_ACCOUNTABLE_PLOT]: {
    fillColor: chroma('#1a6a08'),
    strokeColor: chroma('#1a6a08'),
  },
  [EMapLineType.BUILDING_FOOTPRINT]: {
    fillColor: chroma('#274DA8'),
    strokeColor: chroma('#274DA8'),
    dashArray: [4, 4],
  },
};

export const CONTAMINATION_COLORS = {
  1: {
    fillColor: chroma('#ffe87d'),
    strokeColor: chroma('#ffe87d'),
  },
  2: {
    fillColor: chroma('#3e88e6'),
    strokeColor: chroma('#3e88e6'),
  },
  3: {
    fillColor: chroma('#dca429'),
    strokeColor: chroma('#dca429'),
  },
  4: {
    fillColor: chroma('#FF8336'),
    strokeColor: chroma('#FF8336'),
  },
  5: {
    fillColor: chroma('#FF3636'),
    strokeColor: chroma('#FF3636'),
  },
  unknown: {
    fillColor: chroma('rgba(0, 0, 0, 0)'),
    strokeColor: chroma('rgba(0, 0, 0, 0)'),
  },
};

export const HAZARD_COLORS = {
  2: {
    fillColor: chroma('#FFD736'),
    strokeColor: chroma('#FFD736'),
  },
  3: {
    fillColor: chroma('#276ec9'),
    strokeColor: chroma('#276ec9'),
  },
  4: {
    fillColor: chroma('#FF3636'),
    strokeColor: chroma('#FF3636'),
  },
  unknown: {
    fillColor: chroma('rgba(0, 0, 0, 0)'),
    strokeColor: chroma('rgba(0, 0, 0, 0)'),
  },
};

export const LANDSCAPE_COLORS = {
  forest: {
    fillColor: chroma('#ced6ce'),
  },
  bushForest: {
    fillColor: chroma('#b8c5b8'),
  },
  sparseForest: {
    fillColor: chroma('#a6b5a6'),
  },
  runningWater: {
    fillColor: chroma('#d5e6f6'),
  },
  standingWater: {
    fillColor: chroma('#d5e6f6'),
  },
  unknown: {
    fillColor: chroma('rgba(0, 0, 0, 0)'),
  },
};

export const EXISTING_BUILDING_COLORS = {
  main: {
    fillColor: chroma('rgb(74, 86, 89)'),
    strokeColor: chroma('rgba(74, 86, 89, 0.75)'),
  },
  hover: {
    fillColor: chroma('rgb(31, 198, 118)'),
    strokeColor: chroma('rgb(31, 198, 118)'),
  },
  // Projektiert
  1001: {
    fillColor: chroma(theme.palette.blue[400]),
    strokeColor: chroma(theme.palette.blue[500]),
  },
  // Bewilligt
  1002: {
    fillColor: chroma(theme.palette.blue[300]),
    strokeColor: chroma(theme.palette.blue[400]),
  },
  // Im Bau
  1003: {
    fillColor: chroma(theme.palette.blue[600]),
    strokeColor: chroma(theme.palette.blue[700]),
  },
  // Bestehend
  1004: {
    fillColor: chroma(theme.palette.grey[200]),
    strokeColor: chroma(theme.palette.grey[600]),
  },
  // Nicht nutzbar
  1005: {
    fillColor: chroma('rgb(24, 153, 91)'),
    strokeColor: chroma('rgb(24, 153, 91)'),
  },
  // Abgebrochen
  1007: {
    fillColor: chroma('rgb(31, 198, 118)'),
    strokeColor: chroma('rgb(31, 198, 118)'),
  },
  // Nicht realisiert
  1008: {
    fillColor: chroma('rgb(143, 226, 186)'),
    strokeColor: chroma('rgb(143, 226, 186)'),
  },
};

export const HELPLINES_FILL_COLOR = [
  'match',
  ['get', 'type'],
  ...Object.entries(MAP_LINE_STYLES).flatMap(([type, color]) => [
    type,
    color.fillColor.alpha(DEFAULT_FILL_OPACITY).hex(),
  ]),
  `rgba(${DEFAULT_LAYER_FILL_COLOR[0]}, ${DEFAULT_LAYER_FILL_COLOR[1]}, ${DEFAULT_LAYER_FILL_COLOR[2]}, ${DEFAULT_LAYER_FILL_COLOR[3] / 255})`,
] as const;

export const HELPLINES_LINE_COLOR = [
  'match',
  ['get', 'type'],
  ...Object.entries(MAP_LINE_STYLES).flatMap(([type, color]) => [type, color.strokeColor.hex()]),
  `rgba(${DEFAULT_LAYER_LINE_COLOR[0]}, ${DEFAULT_LAYER_LINE_COLOR[1]}, ${DEFAULT_LAYER_LINE_COLOR[2]}, ${DEFAULT_LAYER_LINE_COLOR[3] / 255})`,
] as const;
