import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EProjectSettingsPage } from 'pages/App/Project/views/Modals/ProjectSettings/types';
import shopRouter from 'routes/routers/shop/shopRouter';
import { EModal } from 'types/enums/Modal';

export type TModalPage = string | null;

interface ISetModalPageData {
  modal: EModal;
  page: TModalPage;
}

interface IModalState {
  open: boolean;
  page: TModalPage;
  backButtonAction: (() => void) | null;
}

interface ISetModal extends Partial<IModalState> {
  modal: EModal;
}

export type ModalsState = Record<EModal, IModalState>;

const DEFAULT_MODAL_STATE = {
  open: false,
  page: null,
  backButtonAction: null,
};

const initialState: ModalsState = {
  [EModal.CALENDLY]: DEFAULT_MODAL_STATE,
  [EModal.CONTACT]: DEFAULT_MODAL_STATE,
  [EModal.DEMO]: DEFAULT_MODAL_STATE,
  [EModal.FAHRLANDER_SETTINGS]: DEFAULT_MODAL_STATE,
  [EModal.HELP]: DEFAULT_MODAL_STATE,
  [EModal.PROJECT_AREAS]: DEFAULT_MODAL_STATE,
  [EModal.PROJECT_CHECKLIST]: DEFAULT_MODAL_STATE,
  [EModal.PROJECT_COSTS]: DEFAULT_MODAL_STATE,
  [EModal.PROJECT_DETAILS]: DEFAULT_MODAL_STATE,
  [EModal.PROJECT_PHOTOVOLTAICS]: DEFAULT_MODAL_STATE,
  [EModal.PROJECT_GEOMETRY]: DEFAULT_MODAL_STATE,
  [EModal.PROJECT_INCOME]: DEFAULT_MODAL_STATE,
  [EModal.PROJECT_SETTINGS]: {
    ...DEFAULT_MODAL_STATE,
    page: EProjectSettingsPage.GENERAL,
  },
  [EModal.PROJECT_SHARE]: DEFAULT_MODAL_STATE,
  [EModal.PROJECT_USAGE]: DEFAULT_MODAL_STATE,
  [EModal.PROJECT_UTILIZATION]: DEFAULT_MODAL_STATE,
  [EModal.SHOP]: DEFAULT_MODAL_STATE,
  [EModal.SUBSCRIPTION]: DEFAULT_MODAL_STATE,
  [EModal.DATA_OVERVIEW]: DEFAULT_MODAL_STATE,
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  selectors: {
    modalsSelector: (state) => state,
  },
  reducers: {
    setModal(state, action: PayloadAction<ISetModal>) {
      const { modal, ...other } = action.payload;

      state[modal] = {
        ...state[modal],
        ...other,
      };
    },
    openModal(state, action: PayloadAction<EModal>) {
      state[action.payload].open = true;
    },
    closeModal(state, action: PayloadAction<EModal>) {
      state[action.payload].open = false;
    },
    setModalPage(state, action: PayloadAction<ISetModalPageData>) {
      const { modal, page } = action.payload;
      state[modal].page = page;
    },
    openProjectModal(state, action: PayloadAction<EModal>) {
      modalsSlice.caseReducers.closeProjectModals(state);
      state[action.payload].open = true;
    },
    closeProjectModals(state) {
      state[EModal.PROJECT_AREAS].open = false;
      state[EModal.PROJECT_CHECKLIST].open = false;
      state[EModal.PROJECT_COSTS].open = false;
      state[EModal.PROJECT_PHOTOVOLTAICS].open = false;
      state[EModal.PROJECT_GEOMETRY].open = false;
      state[EModal.PROJECT_INCOME].open = false;
      state[EModal.PROJECT_SHARE].open = false;
      state[EModal.PROJECT_USAGE].open = false;
      state[EModal.PROJECT_UTILIZATION].open = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type === modalsSlice.actions.setModal.type && action.payload.modal === EModal.SHOP,
      (state) => {
        shopRouter.navigate(state.shop.page).then(() => {});
      },
    );
  },
});

export const { modalsSelector } = modalsSlice.selectors;

export const { openModal, closeModal, setModal, setModalPage, openProjectModal, closeProjectModals } =
  modalsSlice.actions;
