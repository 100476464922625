import { Box, BoxProps, SxProps, Theme } from '@mui/material';
import React from 'react';
import BlackLogoIcon from '../../../assets/images/logo/logo-black-icon.svg';
import BlackLogo from '../../../assets/images/logo/logo-black.svg';

import WhiteLogoIcon from '../../../assets/images/logo/logo-white-icon.svg';

import WhiteLogo from '../../../assets/images/logo/logo-white.svg';

interface LogoProps extends BoxProps {
  size?: string | number;
  color?: 'black' | 'white';
  ImgSx?: SxProps<Theme>;
  type?: 'full' | 'icon';
}

const Logo = (props: LogoProps) => {
  const { color, size = 24, type = 'full', onClick, ImgSx, ...otherProps } = props;

  let src: string;

  if (color === 'white') {
    if (type === 'full') {
      src = WhiteLogo;
    } else {
      src = WhiteLogoIcon;
    }
  } else {
    if (type === 'full') {
      src = BlackLogo;
    } else {
      src = BlackLogoIcon;
    }
  }

  return (
    <Box display='flex' onClick={onClick} {...otherProps} component='div'>
      <Box
        component='img'
        src={src}
        alt='Amenti'
        sx={{
          height: size,
          cursor: onClick ? 'pointer' : 'auto',
          ...ImgSx,
        }}
      />
    </Box>
  );
};

export default Logo;
