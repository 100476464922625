import { Badge, BadgeProps, Box, SvgIconProps, Typography } from '@mui/material';
import React from 'react';
import styles from './styles';

interface ModalHeaderProps {
  title: string;
  Icon?: (props: SvgIconProps) => JSX.Element;
  BadgeProps?: BadgeProps;
}

const ModalHeader = (props: ModalHeaderProps) => {
  const { title, Icon, BadgeProps } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        px: styles.modalPaddingX,
        py: styles.modalPaddingY,
      }}
    >
      {Icon && <Icon sx={{ fontSize: 32, mr: 3 }} />}
      <Badge {...BadgeProps}>
        <Typography variant='h5' component='h2' fontWeight={500}>
          {title}
        </Typography>
      </Badge>
    </Box>
  );
};

export default ModalHeader;
