import { XYZ } from 'types/location/coordinates';

export enum EInteractionType {
  CLICK = 'click',
  HOVER = 'hover',
}

export enum EObjectType {
  BUILDING = 'building',
  FLOOR = 'floor',
  SEGMENT = 'segment',
  ROOF_SEGMENT = 'roofSegment',
  TERRACE_SEGMENT = 'terraceSegment',
  TRANSFORM_WALL = 'transformWall',
  TRANSFORM_CORNER = 'transformCorner',
  BUFFER = 'buffer',
  PARKING_LOT = 'parkingLot',
  VEGETATION = 'vegetation',
  TERRAIN_POINT = 'terrainPoint',
}

export enum EObjectName {
  ACTIVE_VEGETATION = 'activeVegetation',
  RESTORABLE_VEGETATION = 'restorableVegetation',
  TEMPORARY_VEGETATION = 'temporaryVegetation',
  TERRAIN_SURFACE = 'terrainSurface',
  GROUND_BOTTOM = 'groundBottom',
  PARKING_GARAGE = 'parkingGarage',
  PARKING_GARAGE_DOOR = 'parkingGarageDoor',
  EXISTING_BUILDING = 'existingBuilding',
  OFFSET_TERRAIN = 'offsetTerrain',
  TERRAIN_POINT = 'terrainPoint',
  TERRAIN_WIREFRAME = 'terrainWireframe',
}

export enum ELoadingState {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface IModifiedTerrain {
  edgePoints: XYZ[];
  innerPoints: XYZ[];
}

export enum ECameraType {
  PERSPECTIVE = 'perspective',
  ORTHOGRAPHIC = 'orthographic',
}
