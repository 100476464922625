import * as turf from '@turf/turf';
import { union } from '@turf/union';
import { Feature, Polygon } from 'geojson';
import groupBy from 'lodash/groupBy';
import { MapGeoJSONFeature, QuerySourceFeatureOptions } from 'maplibre-gl';
import { ICadastre } from 'pages/App/Explore/ExploreMap/state/map';
import {
  BUILDING_NON_GEODIENSTE_SOURCE_ID,
  CADASTRE_SOURCE_ID,
  CONSTRUCTION_LINES_SOURCE_ID,
  LAND_COVER_SOURCE_ID,
} from '../constants';

interface Map {
  querySourceFeatures: (sourceId: string, options?: QuerySourceFeatureOptions) => MapGeoJSONFeature[];
}

export const querySourceCadastres = (map: Map, options?: QuerySourceFeatureOptions): Feature<Polygon, ICadastre>[] => {
  return map.querySourceFeatures(CADASTRE_SOURCE_ID, {
    sourceLayer: 'default',
    ...options,
  }) as unknown as Feature<Polygon, ICadastre>[];
};

export const querySourceBuildings = (map: Map, options?: QuerySourceFeatureOptions) => {
  const nonGeodiensteBuildings = map.querySourceFeatures(BUILDING_NON_GEODIENSTE_SOURCE_ID, {
    sourceLayer: 'swisstopo.building_3d_30_footprint',
    ...options,
  });

  const geodiensteBuildings = map.querySourceFeatures(LAND_COVER_SOURCE_ID, {
    sourceLayer: LAND_COVER_SOURCE_ID,
    ...options,
  });

  return [...nonGeodiensteBuildings, ...geodiensteBuildings];
};

export const calculateUnionedGeometry = (features: Feature<Polygon>[]): Polygon => {
  if (features.length === 1) {
    return features[0].geometry!;
  } else if (features.length === 0) {
    return turf.polygon([]).geometry;
  }
  return union(turf.featureCollection<Polygon>(features))?.geometry as Polygon;
};

export const getUnionedFeaturesByAttribute = (features: Feature<Polygon>[], property: string): Feature<Polygon>[] => {
  return Object.values(groupBy(features, (f) => f.properties?.[property]))
    .map((features) => {
      if (!features || features.length === 0) return undefined;
      const { id, properties } = features[0];
      const geometry = calculateUnionedGeometry(features);
      return turf.feature(geometry, properties, { id });
    })
    .filter(Boolean)
    .map((f) => {
      return {
        ...f,
        properties: {
          ...f!.properties,
          properties: undefined,
        },
      };
    }) as Feature<Polygon>[];
};

export const getUnionedFeaturesById = (features: Feature<Polygon>[]): Feature<Polygon>[] => {
  return Object.values(groupBy(features, (f) => f.id!))
    .map((features) => {
      if (!features || features.length === 0) return undefined;
      const { id, properties } = features[0];
      const geometry = calculateUnionedGeometry(features);
      return turf.feature(geometry, properties, { id });
    })
    .filter(Boolean) as Feature<Polygon>[];
};

export const querySourceConstructionLines = (map: Map, options?: QuerySourceFeatureOptions) => {
  return map.querySourceFeatures(CONSTRUCTION_LINES_SOURCE_ID, {
    sourceLayer: CONSTRUCTION_LINES_SOURCE_ID,
    ...options,
  });
};
