import { proxyWithHistory } from 'valtio/utils';
import { IModifiedTerrain } from '../types';

interface IModifiedTerrainStoreState {
  modifiedTerrain: IModifiedTerrain;
  buildingsVisible: string[];
}

const modifiedTerrainStore = proxyWithHistory<IModifiedTerrainStoreState>(
  {
    modifiedTerrain: {
      edgePoints: [],
      innerPoints: [],
    },
    buildingsVisible: [],
  },
  false,
);

export const resetModifiedTerrainStoreHistory = () => {
  const lastItem = modifiedTerrainStore.history.snapshots[modifiedTerrainStore.history.index];

  modifiedTerrainStore.history.wip = undefined;
  modifiedTerrainStore.history.index = 0;
  modifiedTerrainStore.history.snapshots = [lastItem];
};

export default modifiedTerrainStore;
