export interface IApplicationPath {
  step: EApplicationStep;
  topic: EApplicationTopic;
  mode: EApplicationMode;
  tool: EApplicationTool;
  selectionFilter: ESelectionFilter;
}

export enum ESelectionFilter {
  NONE = 'none',
  FACADE_WALL_LAYOUT = 'facadeWallLayout',
}

export enum EApplicationStep {
  LOCATION = 'location',
  DESIGN = 'design',
  LAW = 'law',
  SUMMARY = 'summary',
}

export enum EApplicationTopic {
  FORM = 'form',
  FACADE = 'facade',
  PHOTOVOLTAICS = 'photovoltaics',
  LANDSCAPE = 'landscape',
  USAGE = 'usage',
  PARKING_LOTS = 'parkingLots',
  TERRAIN = 'terrain',
  DEFAULT = 'default',
}

export enum EApplicationSubtopic {
  DEFAULT = 'default',
  FORM_BUILDINGS = 'formBuildings',
  FORM_EXISTING_BUILDINGS = 'formExistingBuildings',
  PV_BUILDING = 'photovoltaicsBuilding',
  PV_ROOF = 'photovoltaicsRoof',
  PV_WALLS = 'photovoltaicsWalls',
}

export enum EApplicationMode {
  VIEW = 'view',
  EDIT = 'edit',
  BUFFER = 'bufferEdit',
}

export enum EApplicationTool {
  SELECT = 'selectBuilding',
  MOVE = 'moveBuilding',
  MODIFY = 'modifyBuilding',
  BUILDING = 'buildingFacade',
  FLOOR = 'floor',
  WALL = 'wallFacade',
  FLOOR_WALL = 'floorWall',
  SEGMENT = 'segmentFacade',
  DEFAULT = 'default',
  VEGETATION_SELECT = 'vegetationSelect',
  VEGETATION_ADD = 'vegetationAdd',
  VEGETATION_RESTORE = 'vegetationRestore',
  ROOF_SEGMENT = 'roofSegment',
  ROOF_FACE = 'roofFace',
  ROOF = 'roof',
  TERRAIN_ELEVATION = 'terrainElevation',
}

export enum EApplicationOverrideTool {
  NONE = 'none',
  FACADE_MATERIAL_SETTINGS_PICKER = 'facadeMaterialSettingsPicker',
  FACADE_WALL_LAYOUT_SETTINGS_PICKER = 'facadeWallLayoutSettingsPicker',
  FACADE_BALCONY_SETTINGS_PICKER = 'facadeBalconySettingsPicker',
}

export type TOnlyOnePathPart =
  | ({ topic?: undefined; mode?: undefined; tool?: undefined; selectionFilter?: undefined } & {
      step: EApplicationStep;
    })
  | ({ step?: undefined; mode?: undefined; tool?: undefined; selectionFilter?: undefined } & {
      topic: EApplicationTopic;
    })
  | ({ step?: undefined; topic?: undefined; tool?: undefined; selectionFilter?: undefined } & {
      mode: EApplicationMode;
    })
  | ({ step?: undefined; topic?: undefined; mode?: undefined; selectionFilter?: undefined } & {
      tool: EApplicationTool;
    })
  | ({ step?: undefined; topic?: undefined; mode?: undefined; tool?: undefined } & {
      selectionFilter: ESelectionFilter;
    });

export type TContinuousPathChain =
  | {
      step: EApplicationStep;
      topic: EApplicationTopic;
      mode?: undefined;
      tool?: undefined;
      selectionFilter?: undefined;
    }
  | {
      step: EApplicationStep;
      topic: EApplicationTopic;
      mode: EApplicationMode;
      tool?: undefined;
      selectionFilter?: undefined;
    }
  | {
      step: EApplicationStep;
      topic: EApplicationTopic;
      mode: EApplicationMode;
      tool: EApplicationTool;
      selectionFilter?: undefined;
    }
  | {
      step: EApplicationStep;
      topic: EApplicationTopic;
      mode: EApplicationMode;
      tool: EApplicationTool;
      selectionFilter: ESelectionFilter;
    };
