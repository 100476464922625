import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ECameraType } from 'cityview/types';
import { getValidPath } from 'cityview/utils/applicationPath';
import {
  EApplicationStep,
  EApplicationTopic,
  IApplicationPath,
  TContinuousPathChain,
  TOnlyOnePathPart,
} from 'types/applicationPath';
import { ESolarExposureInterval, ESolarExposureType } from 'types/building/Pv';
import { ETerrainImageType, ETerrainWidth } from 'types/terrain';

export interface IActiveSwissTopoBuilding {
  buildingId: string;
  x: number;
  y: number;
}

export interface CityViewState {
  applicationPath: IApplicationPath;
  settings: {
    settingsSection: {
      active: boolean;
    };
    existingBuildings: {
      active: boolean;
    };
    existingBuildingWireframes: {
      active: boolean;
    };
    terrain: {
      active: boolean;
      width: ETerrainWidth;
    };
    buffers: {
      active: boolean;
    };
    buildings: {
      active: boolean;
    };
    parkingLots: {
      active: boolean;
    };
    restrictions: {
      active: boolean;
    };
    vegetation: {
      active: boolean;
    };
    mapOverlay: {
      active: boolean;
      type: ETerrainImageType;
    };
    grid: {
      active: boolean;
      step: number;
      rotationAngle: number;
    };
    wallSnapping: {
      active: boolean;
      threshold: number;
    };
    cornerSnapping: {
      active: boolean;
      threshold: number;
    };
    camera: {
      type: ECameraType;
    };
    sky: {
      active: boolean;
    };
    solarExposure: {
      open: boolean;
      active: boolean;
      type: ESolarExposureType;
      interval: ESolarExposureInterval;
      intervalMonth: number;
    };
    offsetTerrain: {
      active: boolean;
      heightFromTerrain: number | null;
    };
  };
}

const defaultPath = getValidPath({ step: EApplicationStep.LOCATION });

const initialState: CityViewState = {
  applicationPath: {
    step: defaultPath.step,
    topic: defaultPath.topic,
    mode: defaultPath.mode,
    tool: defaultPath.tool,
    selectionFilter: defaultPath.selectionFilter,
  },
  settings: {
    settingsSection: {
      active: false,
    },
    buildings: {
      active: true,
    },
    parkingLots: {
      active: true,
    },
    existingBuildings: {
      active: true,
    },
    existingBuildingWireframes: {
      active: false,
    },
    terrain: {
      active: true,
      width: ETerrainWidth.SMALL,
    },
    buffers: {
      active: false,
    },
    restrictions: {
      active: true,
    },
    vegetation: {
      active: true,
    },
    mapOverlay: {
      active: true,
      type: ETerrainImageType.ROADMAP,
    },
    grid: {
      active: false,
      step: 10,
      rotationAngle: 0,
    },
    wallSnapping: {
      active: true,
      threshold: 0.7,
    },
    cornerSnapping: {
      active: true,
      threshold: 0.7,
    },
    camera: {
      type: ECameraType.PERSPECTIVE,
    },
    sky: {
      active: true,
    },
    solarExposure: {
      open: false,
      active: false,
      type: ESolarExposureType.IRRADIATION,
      interval: ESolarExposureInterval.YEAR,
      intervalMonth: 6,
    },
    offsetTerrain: {
      active: false,
      heightFromTerrain: null,
    },
  },
};

export const cityViewSlice = createSlice({
  name: 'cityView',
  initialState,
  reducers: {
    clear: () => initialState,
    toggleCityViewSettingsSectionVisibility: (state) => {
      state.settings.settingsSection.active = !state.settings.settingsSection.active;
    },
    toggleExistingBuildingsVisibility(state) {
      state.settings.existingBuildings.active = !state.settings.existingBuildings.active;
    },
    setBuildingsVisibility(state, action: PayloadAction<boolean>) {
      state.settings.buildings.active = action.payload;
    },
    toggleExistingBuildingWireframesVisibility(state) {
      state.settings.existingBuildingWireframes.active = !state.settings.existingBuildingWireframes.active;
    },
    toggleTerrainVisibility(state) {
      state.settings.terrain.active = !state.settings.terrain.active;
    },
    setTerrainVisibility(state, action: PayloadAction<boolean>) {
      state.settings.terrain.active = action.payload;
    },
    toggleBuildingsVisibility(state) {
      state.settings.buildings.active = !state.settings.buildings.active;
    },
    toggleParkingLotsVisibility(state) {
      state.settings.parkingLots.active = !state.settings.parkingLots.active;
    },
    toggleRestrictionsVisibility(state) {
      state.settings.restrictions.active = !state.settings.restrictions.active;
    },
    setVegetationVisibility(state, action: PayloadAction<boolean>) {
      state.settings.vegetation.active = action.payload;
    },
    toggleVegetationVisibility(state) {
      state.settings.vegetation.active = !state.settings.vegetation.active;
    },
    toggleSnapping(state) {
      state.settings.cornerSnapping.active = !state.settings.cornerSnapping.active;
      state.settings.wallSnapping.active = !state.settings.wallSnapping.active;
    },
    setCornerSnappingThreshold(state, action: PayloadAction<number>) {
      state.settings.cornerSnapping.threshold = Math.max(0, action.payload);
    },
    setWallSnappingThreshold(state, action: PayloadAction<number>) {
      state.settings.cornerSnapping.threshold = Math.max(0, action.payload);
    },
    toggleGridVisibility(state) {
      state.settings.grid.active = !state.settings.grid.active;
    },
    setGridStep(state, action: PayloadAction<number>) {
      state.settings.grid.step = action.payload;
    },
    setGridRotationAngle(state, action: PayloadAction<number>) {
      state.settings.grid.rotationAngle = action.payload;
    },
    setMapOverlayType(state, action: PayloadAction<ETerrainImageType>) {
      state.settings.mapOverlay.type = action.payload;
    },
    setTerrainWidth(state, action: PayloadAction<ETerrainWidth>) {
      state.settings.terrain.width = action.payload;
    },
    setCameraType(state, action: PayloadAction<ECameraType>) {
      state.settings.camera.type = action.payload;
    },
    setGridVisibility(state, action: PayloadAction<boolean>) {
      state.settings.grid.active = action.payload;
    },
    setOffsetTerrainVisibility(state, action: PayloadAction<boolean>) {
      state.settings.offsetTerrain.active = action.payload;
    },
    setOffsetTerrainHeight(state, action: PayloadAction<number | null>) {
      state.settings.offsetTerrain.heightFromTerrain = action.payload;
    },
    setSkyVisibility(state, action: PayloadAction<boolean>) {
      state.settings.sky.active = action.payload;
    },
    toggleSkyVisibility(state) {
      state.settings.sky.active = !state.settings.sky.active;
    },
    setSolarExposureVisibility(state, action: PayloadAction<boolean>) {
      state.settings.solarExposure.open = action.payload;
    },
    toggleSolarExposureVisibility(state) {
      state.settings.solarExposure.open = !state.settings.solarExposure.open;
    },
    setSolarExposureStatus(state, action: PayloadAction<boolean>) {
      state.settings.solarExposure.active = action.payload;
    },
    setSolarExposureType(state, action: PayloadAction<ESolarExposureType>) {
      state.settings.solarExposure.type = action.payload;
    },
    setSolarExposureInterval(state, action: PayloadAction<ESolarExposureInterval>) {
      state.settings.solarExposure.interval = action.payload;
    },
    setSolarExposureIntervalMonth(state, action: PayloadAction<number>) {
      state.settings.solarExposure.intervalMonth = action.payload;
    },
    setApplicationPath(state, action: PayloadAction<TOnlyOnePathPart | TContinuousPathChain>) {
      const { step, topic, mode, tool, selectionFilter } = getValidPath(action.payload);

      state.applicationPath = {
        step,
        topic,
        mode,
        tool,
        selectionFilter,
      };

      if (topic !== EApplicationTopic.FACADE && topic !== EApplicationTopic.PHOTOVOLTAICS) {
        state.settings.solarExposure.active = false;
      }
    },
  },
  selectors: {
    cityViewSettingsSelector: (state) => state.settings,
    cityViewCameraTypeSelector: (state) => state.settings.camera.type,
    cityViewTerrainWidthSelector: (state) => state.settings.terrain.width,
    cityViewApplicationPathSelector: (state) => state.applicationPath,
  },
});

export const {
  clear,
  setApplicationPath,
  setBuildingsVisibility,
  setCameraType,
  setGridRotationAngle,
  setGridStep,
  setGridVisibility,
  setOffsetTerrainVisibility,
  setOffsetTerrainHeight,
  setMapOverlayType,
  setSkyVisibility,
  setSolarExposureInterval,
  setSolarExposureIntervalMonth,
  setSolarExposureType,
  setSolarExposureVisibility,
  setSolarExposureStatus,
  setTerrainVisibility,
  setTerrainWidth,
  setVegetationVisibility,
  toggleBuildingsVisibility,
  toggleCityViewSettingsSectionVisibility,
  toggleExistingBuildingWireframesVisibility,
  toggleSolarExposureVisibility,
  toggleExistingBuildingsVisibility,
  toggleGridVisibility,
  toggleParkingLotsVisibility,
  toggleRestrictionsVisibility,
  toggleSkyVisibility,
  toggleSnapping,
  toggleTerrainVisibility,
  toggleVegetationVisibility,
} = cityViewSlice.actions;

export const {
  cityViewSettingsSelector,
  cityViewCameraTypeSelector,
  cityViewTerrainWidthSelector,
  cityViewApplicationPathSelector,
} = cityViewSlice.selectors;
