import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutVisibilityKeySelector } from 'state/selectors/layout';
import { layoutSlice } from 'state/slices';
import { RootState } from 'state/store';
import { getLayoutPathString } from './util';

const { openLayoutVisibility, closeLayoutVisibility, closeAllLayoutsVisibility } = layoutSlice.actions;

interface useLayoutVisibilityProps {
  path: string[];
  initialOpen?: boolean;
}

const useLayoutVisibility = (props: useLayoutVisibilityProps) => {
  const { path, initialOpen = false } = props;

  const key = useMemo(() => getLayoutPathString(path), [path]);

  const dispatch = useDispatch();
  const layoutVisibilityState = useSelector((state: RootState) => layoutVisibilityKeySelector(state, key));

  return {
    isOpen: layoutVisibilityState === undefined ? initialOpen : layoutVisibilityState,
    open: () => dispatch(openLayoutVisibility(key)),
    close: () => dispatch(closeLayoutVisibility(key)),
    closeAll: (path: string[]) => dispatch(closeAllLayoutsVisibility(path)),
  };
};

export default useLayoutVisibility;
