import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LawSelectState {
  selectedZoneId: number | null;
}

const initialState: LawSelectState = {
  selectedZoneId: null,
};

export const lawSelectSlice = createSlice({
  name: 'lawSelect',
  initialState,
  reducers: {
    clear: () => initialState,
    setSelectedZoneId(state, action: PayloadAction<LawSelectState['selectedZoneId']>) {
      state.selectedZoneId = action.payload;
    },
    clearSelectedZoneState(state) {
      state.selectedZoneId = null;
    },
  },
  selectors: {
    selectedZoneIdSelector: (state) => state.selectedZoneId,
  },
});

export const { clear, setSelectedZoneId, clearSelectedZoneState } = lawSelectSlice.actions;

export const { selectedZoneIdSelector } = lawSelectSlice.selectors;
