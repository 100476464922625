import { useSelector } from 'react-redux';
import {
  closeModal,
  closeProjectModals,
  modalsSelector,
  openModal,
  openProjectModal,
  setModal,
  setModalPage,
} from 'state/slices/modals';

const useModals = () => {
  const modals = useSelector(modalsSelector);

  return {
    modals,
    setModal,
    openModal,
    closeModal,
    setModalPage,
    openProjectModal,
    closeProjectModals,
  };
};

export default useModals;
