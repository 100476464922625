import * as THREE from 'three';
import { Vector3 } from 'three';
import { Earcut } from 'three/src/extras/Earcut';
import { EFaceType } from 'types/building/Face';
import { XY, XYZ } from 'types/location/coordinates';

export interface IPoint {
  x: number;
  y: number;
  z: number;
}

export function getHorizontalFaceSegmentCoordinatesFromFootprint(
  footprint: XY[],
  z: number,
  height: number,
  faceType: EFaceType,
): XYZ[] {
  const shift = 0.01;
  const faceZ = faceType === EFaceType.FLOOR ? z + shift : z + height;

  return footprint.map((xy: XY) => [...xy, faceZ]);
}

export const getFaceSegmentGeometry = (
  coordinates: XYZ[],
  faceType: EFaceType,
  segmentWidth: number,
  segmentHeight: number,
  useRelativeCoordinates?: boolean,
) => {
  let segmentCornerCoordinates = coordinates;
  const centerPoint = getCenterPoint(coordinates);

  if (useRelativeCoordinates) {
    segmentCornerCoordinates = coordinates.map((coordinate) => {
      return [coordinate[0] - centerPoint[0], coordinate[1] - centerPoint[1], coordinate[2] - centerPoint[2]];
    });
  }

  // Use a transformed copy of the corners array to avoid mutating the original
  const polygon: IPoint[] = segmentCornerCoordinates.map((coordinate) => {
    return { x: coordinate[0], y: coordinate[1], z: coordinate[2] };
  });

  const normal: IPoint = calculatePolygonSurfaceNormal(polygon);

  // Rotate the polygon so that it lies flat on the x-y plane
  const rotatedPolygon: IPoint[] =
    faceType !== EFaceType.FLOOR && faceType !== EFaceType.CEILING
      ? normalizePolygonRotation(polygon, normal)
      : polygon;
  const rotatedVertexData: number[] = [];
  rotatedPolygon.forEach((point) => {
    rotatedVertexData.push(point.x, point.y);
  });

  // Triangulate the rotated polygon as a collection of 2D points
  const triangulatedFaces: number[] = Earcut.triangulate(rotatedVertexData);
  const vertexPositions: number[] = [];

  triangulatedFaces.forEach((index) => {
    const point = polygon[index];
    vertexPositions.push(point.x, point.y, point.z);
  });

  const halfSegmentWith = segmentWidth / 2;
  const halfSegmentHeight = segmentHeight / 2;

  const vertexUVs: number[] = [];
  triangulatedFaces.forEach((index) => {
    const point = rotatedPolygon[index];
    vertexUVs.push(point.x / halfSegmentWith, point.y / halfSegmentHeight);
  });

  // create geometry
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(vertexPositions), 3));
  geometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(vertexUVs), 2));

  return {
    geometry,
    triangulatedFaces,
    segmentNormal: [normal.x, normal.y, normal.z] as XYZ,
    centerPoint,
  };
};

export const getParkingLotSurfaceVertexPositions = (corners: XY[], z: number) => {
  // Use a transformed copy of the corners array to avoid mutating the original
  const polygon: IPoint[] = corners.map((coordinate) => {
    return { x: coordinate[0], y: coordinate[1], z };
  });

  const vertexData: number[] = [];
  polygon.forEach((point) => {
    vertexData.push(point.x, point.y);
  });

  // Triangulate the rotated polygon as a collection of 2D points
  const triangulatedFaces: number[] = Earcut.triangulate(vertexData);
  const vertexPositions: number[] = [];

  triangulatedFaces.forEach((index) => {
    const point = polygon[index];
    vertexPositions.push(point.x, point.y, point.z);
  });

  return vertexPositions;
};

export const getCenterPoint = (coordinates: XYZ[]): XYZ => {
  const x = coordinates.reduce((acc, curr) => acc + curr[0], 0) / coordinates.length;
  const y = coordinates.reduce((acc, curr) => acc + curr[1], 0) / coordinates.length;
  const z = coordinates.reduce((acc, curr) => acc + curr[2], 0) / coordinates.length;

  return [x, y, z];
};

const round = (x: number, decimals = 0): number => {
  const rounder = Math.pow(10, decimals);
  return Math.round(x * rounder) / rounder;
};

export const crossProduct = (vector1: IPoint, vector2: IPoint): IPoint => {
  const { x: x1, y: y1, z: z1 } = vector1;
  const { x: x2, y: y2, z: z2 } = vector2;

  return {
    x: y1 * z2 - z1 * y2,
    y: z1 * x2 - x1 * z2,
    z: x1 * y2 - y1 * x2,
  };
};

export const findSuitableCorners = (polygon: IPoint[]): IPoint[] => {
  const suitablePoints: IPoint[] = [];

  for (let i = 0; i < polygon.length - 2; i++) {
    for (let j = i + 1; j < polygon.length - 1; j++) {
      for (let k = j + 1; k < polygon.length; k++) {
        const vector1 = {
          x: polygon[j].x - polygon[i].x,
          y: polygon[j].y - polygon[i].y,
          z: polygon[j].z - polygon[i].z,
        };

        const vector2 = {
          x: polygon[k].x - polygon[i].x,
          y: polygon[k].y - polygon[i].y,
          z: polygon[k].z - polygon[i].z,
        };

        const normal = {
          x: vector1.y * vector2.z - vector1.z * vector2.y,
          y: vector1.z * vector2.x - vector1.x * vector2.z,
          z: vector1.x * vector2.y - vector1.y * vector2.x,
        };

        const magnitude = Math.sqrt(normal.x * normal.x + normal.y * normal.y + normal.z * normal.z);

        if (magnitude !== 0) {
          return [polygon[i], polygon[j], polygon[k]];
        }
      }
    }
  }

  return suitablePoints;
};

export const calculatePolygonSurfaceNormal = (polygon: IPoint[]): IPoint => {
  const triangle = findSuitableCorners(polygon);
  if (!triangle.length) {
    throw new Error('Cannot find valid surface normal');
  }

  // Calculate the cross product of two edges of the polygon to determine its surface normal
  const edge1: IPoint = {
    x: triangle[1].x - triangle[0].x,
    y: triangle[1].y - triangle[0].y,
    z: triangle[1].z - triangle[0].z,
  };

  const edge2: IPoint = {
    x: triangle[2].x - triangle[0].x,
    y: triangle[2].y - triangle[0].y,
    z: triangle[2].z - triangle[0].z,
  };

  const normal: IPoint = findPlaneNormal(edge1, edge2);

  // Normalize the surface normal
  const length: number = Math.sqrt(normal.x * normal.x + normal.y * normal.y + normal.z * normal.z);
  return {
    x: normal.x / length,
    y: normal.y / length,
    z: normal.z / length,
  };
};

const findPlaneNormal = (vector1: IPoint, vector2: IPoint): IPoint => {
  return {
    x: vector1.y * vector2.z - vector1.z * vector2.y,
    y: vector1.z * vector2.x - vector1.x * vector2.z,
    z: vector1.x * vector2.y - vector1.y * vector2.x,
  };
};

const normalizePolygonRotation = (polygon: IPoint[], normal: IPoint): IPoint[] => {
  const normalVector = new Vector3(normal.x, normal.y, normal.z);
  const xVector = new Vector3(1, 0, 0);
  const yVector = new Vector3(0, 1, 0);
  const zVector = new Vector3(0, 0, 1);

  const xyDirection = new Vector3(normal.x, normal.y, 0).normalize();
  const xyRotationAxis = new Vector3().crossVectors(xyDirection, yVector);
  const signXY = Math.sign(xyRotationAxis.z);
  const zRotationAngle = Math.acos(xyDirection.dot(yVector)) * signXY;

  const rotatedNormal = normalVector.applyAxisAngle(zVector, zRotationAngle);
  const yzDirection = new Vector3(0, rotatedNormal.y, rotatedNormal.z).normalize();
  const xRotationAngle = Math.acos(yzDirection.dot(zVector));

  // Perform the rotation
  return polygon.map((point) => {
    // turn the polygon to face positive y-axis direction with a possible tilt on x-axis
    const zRotatedPoint: Vector3 = new Vector3(point.x, point.y, point.z).applyAxisAngle(zVector, zRotationAngle);
    // turn the polygon to face positive z-axis direction
    const xRotatedPoint = zRotatedPoint.applyAxisAngle(xVector, xRotationAngle);
    return {
      x: round(xRotatedPoint.x, 2),
      y: round(xRotatedPoint.y, 2),
      z: round(xRotatedPoint.z, 2),
    };
  });
};

export const typeXYZtoPoint = (xyz: XYZ): IPoint => {
  return { x: xyz[0], y: xyz[1], z: xyz[2] };
};

export const typeVectorToNumberArray = (vector: IPoint): number[] => {
  return [vector.x, vector.y, vector.z];
};
