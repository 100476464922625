import { XY } from 'types/location/coordinates';
import { EParkingLotType, TParkingLotOutdoor, TParkingLots } from 'types/parking';
import { derive, proxyWithHistory } from 'valtio/utils';
import { getOutdoorParkingLotLines } from '../utils/parking-lot/outdoorParking';

type TParkingLotID = string;
type TOutdoorParkingLotLines = Record<TParkingLotID, [XY, XY][]>;

interface IParkingLotStoreState {
  parkingLots: TParkingLots;
}

const parkingLotStore = proxyWithHistory<IParkingLotStoreState>(
  {
    parkingLots: {},
  },
  false,
);

export const resetParkingLotStoreHistory = () => {
  const lastItem = parkingLotStore.history.snapshots[parkingLotStore.history.index];

  parkingLotStore.history.wip = undefined;
  parkingLotStore.history.index = 0;
  parkingLotStore.history.snapshots = [lastItem];
};

export default parkingLotStore;

export const derivedParkingLotStore = derive({
  outdoorParkingLotLines: (get) => {
    const parkingLots = get(parkingLotStore).value.parkingLots;
    const outdoorParkingLots = Object.values(parkingLots).filter(
      (lot): lot is TParkingLotOutdoor => lot.properties.type === EParkingLotType.OUTDOOR_PARKING,
    );

    const lines: TOutdoorParkingLotLines = {};
    outdoorParkingLots.forEach((lot) => {
      lines[lot.id] = getOutdoorParkingLotLines(lot);
    });

    return lines;
  },
});
