import * as THREE from 'three';
import {
  EApplicationMode,
  EApplicationOverrideTool,
  EApplicationStep,
  EApplicationTool,
  EApplicationTopic,
  ESelectionFilter,
  IApplicationPath,
} from 'types/applicationPath';
import { TBuildings } from 'types/building/Building';
import { IBufferConstructionSettings } from 'types/building/BuildingBuffers';
import { ESolarExposureInterval, ESolarExposureType } from 'types/building/Pv';
import { ILngLat } from 'types/location/coordinates';
import { ETerrainWidth, TTerrain } from 'types/terrain';
import { Nullable } from 'types/util';
import { proxy, ref } from 'valtio';
import { derive } from 'valtio/utils';
import { EObjectType } from '../types';
import AnimatedValue from '../utils/AnimatedValue';
import { EVegetationType } from './landscapeStore';

export interface ICityViewGeneral {
  disableClickInteraction: boolean;
  isDesktop: boolean;
  applicationPath: IApplicationPath;
  overrideTool: EApplicationOverrideTool;
  center: ILngLat;
}

export interface ICityViewBuffers {
  bufferConstructionSettings: Nullable<IBufferConstructionSettings>;
}

export interface ICityViewMouse {
  isPersistingChanges: boolean;
  isMouseDown: boolean;
  isMouseEditing: boolean;
  isMouseOnGizmoDown: boolean;
  isTransformingCamera: boolean;
}

export interface ITexts {
  solarTooltipTitle: {
    [ESolarExposureType.IRRADIATION]: {
      [ESolarExposureInterval.YEAR]: string;
      [ESolarExposureInterval.MONTH]: string;
    };
    [ESolarExposureType.SHADING_FACTOR]: {
      [ESolarExposureInterval.YEAR]: string;
      [ESolarExposureInterval.MONTH]: string;
    };
  };
  solarTooltipUnit: {
    [ESolarExposureType.IRRADIATION]: string;
    [ESolarExposureType.SHADING_FACTOR]: string;
  };
  usageTooltipTitle: {
    livingSpaceRatio: string;
    businessSpaceRatio: string;
    sideUsageSpaceRatio: string;
  };
  roofPvCoverage: string;
  terrainEdit: {
    unit: string;
  };
}

export interface ICityViewSettings {
  terrain: {
    width: ETerrainWidth;
    showTerrain: boolean;
  };
  clipping: {
    active: boolean;
    constant: number;
    angle: number;
  };
  wallSnapping: {
    active: boolean;
    threshold: number;
  };
  cornerSnapping: {
    active: boolean;
    threshold: number;
  };
  solarExposure: {
    active: boolean;
    type: ESolarExposureType;
    interval: ESolarExposureInterval;
    intervalMonth: number;
  };
  offsetTerrain: {
    active: boolean;
    heightFromTerrain: number | null;
  };
}

interface IStoreState {
  general: ICityViewGeneral;
  mouse: ICityViewMouse;
  existingBuildings: TBuildings;
  animations: Record<string, AnimatedValue>;
  selections: {
    selectedObjects: Record<string, EObjectType>;
  };
  buffers: ICityViewBuffers;
  texts: ITexts;
  landscape: {
    addVegetationType: EVegetationType;
  };
  settings: ICityViewSettings;
  terrainGeometry: {
    id?: string;
    bottom?: THREE.BufferGeometry;
    overlay?: THREE.BufferGeometry;
    terrainData?: TTerrain;
    midpointElevation?: number;
  };
}

export const initialStoreState: IStoreState = {
  general: {
    disableClickInteraction: false,
    isDesktop: true,
    overrideTool: EApplicationOverrideTool.NONE,
    applicationPath: {
      // NB! Do not change these values for temporary dev purposes. Instead, go to useEditModeControls in useCityviewControls.tsx and change the useEffect values.
      step: EApplicationStep.LOCATION,
      topic: EApplicationTopic.DEFAULT,
      mode: EApplicationMode.VIEW,
      tool: EApplicationTool.DEFAULT,
      selectionFilter: ESelectionFilter.NONE,
    },
    center: {
      lng: 8.52193,
      lat: 47.3867,
    },
  },
  settings: {
    terrain: {
      width: ETerrainWidth.SMALL,
      showTerrain: true,
    },
    clipping: {
      active: false,
      constant: 0,
      angle: 0,
    },
    wallSnapping: {
      active: true,
      threshold: 0.7,
    },
    cornerSnapping: {
      active: true,
      threshold: 0.7,
    },
    solarExposure: {
      active: false,
      type: ESolarExposureType.IRRADIATION,
      interval: ESolarExposureInterval.YEAR,
      intervalMonth: 6,
    },
    offsetTerrain: {
      active: false,
      heightFromTerrain: null,
    },
  },
  animations: {
    daylightMonth: ref(
      new AnimatedValue(6, {
        moveConfig: {
          inertia: 20,
          target: 6,
        },
      }),
    ),
    daylightMinutes: ref(
      new AnimatedValue(720, {
        tweenConfig: {
          duration: 5000,
          from: 540,
          to: 1260,
          loop: true,
          easing: 'inOutQuad',
        },
      }),
    ),
    cameraAngle: ref(
      new AnimatedValue(-Math.PI / 4, {
        moveConfig: {
          inertia: 100,
          target: -Math.PI / 4,
        },
      }),
    ),
    cameraElevation: ref(new AnimatedValue(200)),
    cameraRadius: ref(new AnimatedValue(450)),
  },
  selections: {
    selectedObjects: {},
  },
  mouse: {
    isPersistingChanges: false,
    isMouseDown: false,
    isMouseEditing: false,
    isMouseOnGizmoDown: false,
    isTransformingCamera: false,
  },
  buffers: {
    bufferConstructionSettings: null,
  },
  existingBuildings: {},
  texts: {
    solarTooltipTitle: {
      [ESolarExposureType.IRRADIATION]: {
        [ESolarExposureInterval.YEAR]: 'Yearly irradiation',
        [ESolarExposureInterval.MONTH]: 'Monthly irradiation',
      },
      [ESolarExposureType.SHADING_FACTOR]: {
        [ESolarExposureInterval.YEAR]: 'Yearly shading factor',
        [ESolarExposureInterval.MONTH]: 'Monthly shading factor',
      },
    },
    solarTooltipUnit: {
      [ESolarExposureType.IRRADIATION]: 'kWh/m²',
      [ESolarExposureType.SHADING_FACTOR]: '%',
    },
    usageTooltipTitle: {
      livingSpaceRatio: 'Living space',
      businessSpaceRatio: 'Business space',
      sideUsageSpaceRatio: 'Side usage space',
    },
    roofPvCoverage: 'Roof PV coverage:',
    terrainEdit: {
      unit: 'm',
    },
  },
  landscape: {
    addVegetationType: EVegetationType.MAPLE,
  },
  terrainGeometry: {
    id: undefined,
    bottom: undefined,
    overlay: undefined,
    terrainData: undefined,
  },
};

const store = proxy<IStoreState>(initialStoreState);

export const derivedStore = derive({
  selectedObjects: (get) => {
    const { selectedObjects } = get(store.selections);
    const selectedObjectsArray = Object.entries(selectedObjects);

    const groupedSelectedObjects = selectedObjectsArray.reduce(
      (acc, [id, type]) => {
        if (!acc[type]) {
          acc[type] = [];
        }

        acc[type].push(id);

        return acc;
      },
      {} as Record<EObjectType, string[]>,
    );

    return {
      selectedBuildingIds: groupedSelectedObjects[EObjectType.BUILDING] ?? [],
      selectedFloorIds: groupedSelectedObjects[EObjectType.FLOOR] ?? [],
      selectedSegmentIds: groupedSelectedObjects[EObjectType.SEGMENT] ?? [],
      selectedRoofSegmentIds: groupedSelectedObjects[EObjectType.ROOF_SEGMENT] ?? [],
      selectedTerraceSegmentIds: groupedSelectedObjects[EObjectType.TERRACE_SEGMENT] ?? [],
      selectedParkingIds: groupedSelectedObjects[EObjectType.PARKING_LOT] ?? [],
      selectedVegetationIds: groupedSelectedObjects[EObjectType.VEGETATION] ?? [],
      selectedBufferIds: groupedSelectedObjects[EObjectType.BUFFER] ?? [],
      selectedTransformCornerIds: groupedSelectedObjects[EObjectType.TRANSFORM_CORNER] ?? [],
      selectedTransformWallIds: groupedSelectedObjects[EObjectType.TRANSFORM_WALL] ?? [],
      selectedTerrainPointIndices: (groupedSelectedObjects[EObjectType.TERRAIN_POINT] ?? []).map((index) =>
        parseInt(index),
      ),
    };
  },
});

export default store;
