import { Box, CircularProgress, IconButton, InputAdornment, StandardTextFieldProps, TextField } from '@mui/material';
import React from 'react';
import styles from './styles';

interface SearchFieldProps extends Omit<StandardTextFieldProps, 'sx' | 'variant' | 'size'> {
  Icon?: React.ReactNode;
  ButtonIcon?: React.ReactNode;
  handleSearch?: () => void;
  isLoading?: boolean;
}

const SearchField = (props: SearchFieldProps) => {
  const { isLoading, Icon, ButtonIcon, handleSearch, InputProps, ...otherProps } = props;

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (handleSearch && event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <TextField
      {...otherProps}
      size='small'
      variant='outlined'
      sx={styles.field}
      classes={undefined}
      onKeyUp={handleKeyUp}
      InputProps={{
        ...InputProps,
        startAdornment: Icon ? <InputAdornment position='start'>{Icon}</InputAdornment> : InputProps?.startAdornment,
        endAdornment:
          ButtonIcon && handleSearch ? (
            <InputAdornment position='end'>
              <IconButton
                color='primary'
                sx={{ bgcolor: 'grey.100', p: 1 }}
                aria-label='directions'
                onClick={handleSearch}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress color='inherit' size={24} /> : ButtonIcon}
              </IconButton>
              {InputProps?.endAdornment && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '1px', height: 28, borderRight: 1, borderColor: 'grey.300', ml: 1.5, mr: 3 }} />
                  {InputProps?.endAdornment}
                </Box>
              )}
            </InputAdornment>
          ) : (
            InputProps?.endAdornment
          ),
      }}
    />
  );
};

export default SearchField;
