import { createSelector } from 'reselect';
import { LayoutState, LayoutViewState, LayoutVisibilityState } from '../slices/layout';
import { RootState } from '../store';

export const layoutSelector: (state: RootState) => LayoutState = (state: RootState) => state.layout;

export const layoutVisibilitySelector: (state: RootState) => LayoutVisibilityState = (state: RootState) =>
  state.layout.visibility;

export const layoutVisibilityKeySelector = createSelector(
  [layoutVisibilitySelector, (_, key: string) => key],
  (state, key) => state[key],
);

export const layoutViewSelector: (state: RootState) => LayoutViewState = (state: RootState) => state.layout.view;

export const layoutViewKeySelector = createSelector(
  [layoutViewSelector, (_, key: string) => key],
  (state, key) => state[key],
);

export const sidebarOpenSelector = createSelector(layoutSelector, (state) => state.visibility.sidebar);
