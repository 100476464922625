import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EZoneType } from 'api/dtos/law/enums';
import { ESearchType } from 'types/enums/SearchType';

export enum EFilterSection {
  PLOT = 'plot',
  BUILDINGS = 'buildings',
  LAW = 'law',
}

export enum EFilter {
  HEIGHT_DIFFERENCE = 'heightDifference',
  AREA = 'area',
  CONSTRUCTION_YEAR = 'year',
  FOOTPRINT_AREA = 'grundflaeche',
  ZONE_TYPE = 'zoneType',
  LIVING_ALLOWED = 'livingAllowed',
  COMMERCIAL_ALLOWED = 'commercialAllowed',
  INDUSTRIAL_ALLOWED = 'industrialAllowed',
  HOTEL_ZONE = 'hotelZone',
  CENTRAL_ZONE = 'centralZone',
  FAMILY_HOUSING_ZONE = 'familyHousingZone',
  RESERVED = 'reserved',
  COMMERCIAL_BONUS = 'commercialBonus',
  SPECIAL_REGULATION = 'specialRegulation',
  MAIN_FLOORS_EST = 'mainFloorsEst',
  FLOORS_EST = 'floorsEst',
  HAS_EXISTING_BUILDINGS = 'hasExistingBuildings',
}

interface IExploreStateFilterSection {
  open: boolean;
}

export type TExploreStateFilterSections = Record<EFilterSection, IExploreStateFilterSection>;

interface TFilterField<T> {
  active: boolean;
  value: T;
}

export interface IExploreStateFilters {
  [EFilter.HEIGHT_DIFFERENCE]: TFilterField<[number, number]>;
  [EFilter.AREA]: TFilterField<[number, number]>;
  [EFilter.CONSTRUCTION_YEAR]: TFilterField<[number, number]>;
  [EFilter.FOOTPRINT_AREA]: TFilterField<[number, number]>;
  [EFilter.ZONE_TYPE]: TFilterField<EZoneType[]>;
  [EFilter.LIVING_ALLOWED]: TFilterField<boolean>;
  [EFilter.COMMERCIAL_ALLOWED]: TFilterField<boolean>;
  [EFilter.INDUSTRIAL_ALLOWED]: TFilterField<boolean>;
  [EFilter.HOTEL_ZONE]: TFilterField<boolean>;
  [EFilter.CENTRAL_ZONE]: TFilterField<boolean>;
  [EFilter.FAMILY_HOUSING_ZONE]: TFilterField<boolean>;
  [EFilter.RESERVED]: TFilterField<boolean>;
  [EFilter.COMMERCIAL_BONUS]: TFilterField<boolean>;
  [EFilter.SPECIAL_REGULATION]: TFilterField<boolean>;
  [EFilter.MAIN_FLOORS_EST]: TFilterField<[number, number]>;
  [EFilter.FLOORS_EST]: TFilterField<[number, number]>;
  [EFilter.HAS_EXISTING_BUILDINGS]: TFilterField<boolean>;
}

export interface ExploreState {
  filter: {
    active: boolean;
    sections: TExploreStateFilterSections;
    filters: IExploreStateFilters;
    includeUnknownLawValues: boolean;
  };
  search: {
    isLoading: boolean;
    addressSearch: {
      search: string;
    };
    plotNumberSearch: {
      municipality: string;
      plotNumber: string;
    };
    type: ESearchType;
  };
}

const initialState: ExploreState = {
  filter: {
    active: false,
    sections: {
      [EFilterSection.PLOT]: {
        open: true,
      },
      [EFilterSection.BUILDINGS]: {
        open: true,
      },
      [EFilterSection.LAW]: {
        open: true,
      },
    },
    filters: {
      [EFilter.HEIGHT_DIFFERENCE]: {
        active: false,
        value: [0, 10],
      },
      [EFilter.AREA]: {
        active: false,
        value: [10, 15000],
      },
      [EFilter.CONSTRUCTION_YEAR]: {
        active: false,
        value: [1950, 2000],
      },
      [EFilter.FOOTPRINT_AREA]: {
        active: false,
        value: [50, 500],
      },
      [EFilter.ZONE_TYPE]: {
        active: false,
        value: [EZoneType.LIVING_ZONE],
      },
      [EFilter.LIVING_ALLOWED]: {
        active: false,
        value: true,
      },
      [EFilter.COMMERCIAL_ALLOWED]: {
        active: false,
        value: true,
      },
      [EFilter.INDUSTRIAL_ALLOWED]: {
        active: false,
        value: true,
      },
      [EFilter.HOTEL_ZONE]: {
        active: false,
        value: true,
      },
      [EFilter.CENTRAL_ZONE]: {
        active: false,
        value: true,
      },
      [EFilter.FAMILY_HOUSING_ZONE]: {
        active: false,
        value: true,
      },
      [EFilter.RESERVED]: {
        active: false,
        value: true,
      },
      [EFilter.COMMERCIAL_BONUS]: {
        active: false,
        value: true,
      },
      [EFilter.SPECIAL_REGULATION]: {
        active: false,
        value: true,
      },
      [EFilter.MAIN_FLOORS_EST]: {
        active: false,
        value: [1, 20],
      },
      [EFilter.FLOORS_EST]: {
        active: false,
        value: [1, 20],
      },
      [EFilter.HAS_EXISTING_BUILDINGS]: {
        active: false,
        value: true,
      },
    },
    includeUnknownLawValues: false,
  },
  search: {
    isLoading: false,
    addressSearch: {
      search: '',
    },
    plotNumberSearch: {
      municipality: '',
      plotNumber: '',
    },
    type: ESearchType.ADDRESS,
  },
};

interface ISetFilterSectionFieldActivePayload {
  key: EFilter;
  active: boolean;
}

interface ISetFilterSectionFieldValuePayload<T extends EFilter> {
  key: T;
  value: IExploreStateFilters[T]['value'];
}

export const exploreSlice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    clear: () => initialState,
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.search.isLoading = action.payload;
    },
    setSearchType(state, action: PayloadAction<ESearchType>) {
      state.search.type = action.payload;
    },
    setFilterActive(state, action: PayloadAction<boolean>) {
      state.filter.active = action.payload;
    },
    toggleFilter(state) {
      state.filter.active = !state.filter.active;
    },
    toggleFilterSectionOpen(state, action: PayloadAction<EFilterSection>) {
      state.filter.sections[action.payload].open = !state.filter.sections[action.payload].open;
    },
    setFilterSectionFilterActive(state, action: PayloadAction<ISetFilterSectionFieldActivePayload>) {
      state.filter.filters[action.payload.key].active = action.payload.active;
    },
    setFilterSectionFilterValue(state, action: PayloadAction<ISetFilterSectionFieldValuePayload<EFilter>>) {
      state.filter.filters[action.payload.key].value = action.payload.value;
    },
    setIncludeUnknownLawValues(state, action: PayloadAction<boolean>) {
      state.filter.includeUnknownLawValues = action.payload;
    },
  },
});

export const {
  clear,
  setSearchType,
  setFilterActive,
  toggleFilter,
  setIsLoading,
  toggleFilterSectionOpen,
  setFilterSectionFilterActive,
  setFilterSectionFilterValue,
  setIncludeUnknownLawValues,
} = exploreSlice.actions;
